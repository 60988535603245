import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Head from "next/head";
import Image from "next/image";
import { useState } from "react";

type SigninPropType = {
  signIn: (type: string) => void;
  error: ERROR_TYPE;
  csrfToken: string;
};

type ERROR_TYPE =
  | "EmailCreateAccount"
  | "EmailSignin"
  | "SessionRequired"
  | "OAuthCallback"
  | "OAuthSignin"
  | "CredentialsSignin";

const ERRORS: Record<ERROR_TYPE, string> = {
  EmailCreateAccount: "Could not create email provider user in the database.",
  EmailSignin: "Sending the e-mail with the verification token failed",
  SessionRequired:
    "The content of this page requires you to be signed in at all times.",
  OAuthCallback: "Something went wrong with Google OAuth Callback",
  OAuthSignin: "Something went wrong with Google OAuth Servers",
  CredentialsSignin: "Invalid credentials provided",
};

export function Signin({ signIn, error, csrfToken }: SigninPropType) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  return (
    <>
      <Head>
        <title>FULFLLD | Cloud Delivery Management</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <style>{"html { overflow: hidden; }"}</style>
        <link
          rel="icon"
          href="https://cdn-assets-app.fulflld.com/favicon.png"
          type="image/png"
        />
      </Head>
      <div className="h-screen">
        <div className="flex min-h-full flex-1">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <Image
                  className="h-10 w-auto"
                  src="https://cdn-assets-app.fulflld.com/images/logo.svg"
                  alt="Your Company"
                  width={10}
                  height={100}
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  Not a member?{" "}
                  <a
                    href="mailto:contact@fulflld.com"
                    className="font-semibold text-gray-600 hover:text-gray-500"
                  >
                    Reach out to contact@fulflld.com
                  </a>
                </p>
              </div>

              {error && (
                <div
                  className="mb-4 rounded-lg bg-yellow-100 p-4 text-sm text-yellow-700 dark:bg-yellow-200 dark:text-yellow-800"
                  role="alert"
                >
                  <span className="font-medium">Error!</span> {ERRORS[error]}
                </div>
              )}

              <div className="mt-10">
                <div>
                  <form
                    className="space-y-6"
                    method="post"
                    action={
                      !isPasswordVisible
                        ? "/api/auth/signin/email"
                        : "/api/auth/callback/credentials"
                    }
                  >
                    <input
                      name="csrfToken"
                      type="hidden"
                      defaultValue={csrfToken}
                    />
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 p-1.5 shadow-sm ring-2 ring-inset ring-gray-300 hover:ring-gray-700 focus:ring-gray-700 focus:outline-0 sm:text-sm sm:leading-6 transition ease-in"
                        />
                      </div>
                    </div>

                    {isPasswordVisible && (
                      <div>
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Password
                        </label>
                        <div className="mt-2">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 p-1.5 shadow-sm ring-2 ring-inset ring-gray-300 hover:ring-gray-700 focus:ring-gray-700 focus:outline-0 sm:text-sm sm:leading-6 transition ease-in"
                          />
                        </div>
                      </div>
                    )}

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm transition ease-in hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>

                <div className="flex items-center justify-between mt-5">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => setIsPasswordVisible(!isPasswordVisible)}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 font-normal text-gray-900"
                    >
                      Sign in with password
                    </label>
                  </div>
                </div>

                <div className="mt-10">
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <div className="mt-6">
                    <span
                      className="cursor-pointer flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent transition ease-in"
                      onClick={() => {
                        setIsGoogleLoading(true);
                        signIn("google");
                      }}
                    >
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                          fill="#EA4335"
                        />
                        <path
                          d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                          fill="#34A853"
                        />
                      </svg>
                      <span className="text-sm font-semibold leading-6 flex items-center gap-8">
                        Google
                        {isGoogleLoading && (
                          <FontAwesomeIcon
                            icon={faCircleNotch}
                            className="animate-spin "
                          />
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://cdn.prod.website-files.com/65b7d5fb2bef674620e3be73/6643e75ce11d57f58211f7b1_F%20Road%203.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
