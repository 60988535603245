import Head from "next/head";
import Image from "next/image";

type ERROR_TYPE = "Verification" | "AccessDenied";

const ERRORS: Record<ERROR_TYPE, string> = {
  Verification: "The token has expired or has already been used",
  AccessDenied: "Access Denied",
};

export function Error({ error }: { error: ERROR_TYPE }) {
  return (
    <>
      <Head>
        <title>FULFLLD | Cloud Delivery Management</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <style>{"html { overflow: hidden; }"}</style>
        <link
          rel="icon"
          href="https://cdn-assets-app.fulflld.com/favicon.png"
          type="image/png"
        />
      </Head>
      <div className="h-screen">
        <div className="flex min-h-full flex-1">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <Image
                  className="h-10 w-auto"
                  src="https://cdn-assets-app.fulflld.com/images/logo.svg"
                  alt="Your Company"
                  width={10}
                  height={100}
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Something went wrong
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  {ERRORS[error]}
                </p>
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://cdn.prod.website-files.com/65b7d5fb2bef674620e3be73/6643e75ce11d57f58211f7b1_F%20Road%203.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
